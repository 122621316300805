import React, { useState, useEffect } from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { z } from 'zod';
import styles from '../styles/Home.module.scss';
import { signIn } from 'next-auth/react';
import { useForm, zodResolver } from '@mantine/form';
import { useIntercom } from 'react-use-intercom';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
    LoadingOverlay,
    Space,
    Switch,
    Button,
    Modal,
    Text,
    Box,
    TextInput,
    PasswordInput,
    Group,
} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import authApiInterface from '../services/auth-api-interface';
import toast from 'react-hot-toast';
import { useTranslation } from 'next-i18next';
import { Trans } from 'next-i18next';
//import { Trans } from 'react-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Home: NextPage = () => {

    const { t } = useTranslation();
    const router = useRouter();
    const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showOneTimePassword, setShowOneTimePassword] = useState(false);
    const [isRegisterModalOpened, setIsRegisterModalOpened] = useState(false);
    const { update } = useIntercom();
    useEffect(() => {

        update({name: 'guest'});

    }, []);

    const emailValidator =  z.string().email({ message: 'Invalid email' });
    const passwordValidator =  z.string().min(8, {message: 'Password must be at least 8 character' });

    const schema = z.object({
        email: emailValidator,//z.string().email({ message: 'Invalid email' }),
        password: passwordValidator,
    });

    interface FormValues {
        email: string;
        password: string;
        oneTimePassword: string;
    }

    const loginForm = useForm<FormValues>({
        validate: zodResolver(schema),
        initialValues: {
            email: '',
            password: '',
            oneTimePassword: '',
        },
    });

    const zvalidate = (validator: z.ZodString, password: string) => {

        const result = validator.safeParse(password);
        if (result.success) {
            return null;
        } else {
            const parsed = JSON.parse(result.error.message);
            return parsed[0].message;
        }
    };

    const registerForm = useForm<{ name: string; email: string, password: string, passwordConfirm: string, termsOfService: boolean, privacy: boolean }>({
        initialValues: { name: '', email: '', password: '', passwordConfirm: '', termsOfService: false, privacy: false },
        validate: (values) => ({
            name: values.name.length < 2 ? 'Name is too short' : null,
            email: zvalidate(emailValidator, values.email),
            password: zvalidate(passwordValidator, values.password),
            passwordConfirm: values.password !== values.passwordConfirm ? 'Passwords must match' : null,
            termsOfService: values.termsOfService === true ? null : 'You must accept the AGB',
            privacy: values.privacy === true ? null : 'You must accept the privacy policy',
        }),
    });

    return (
        <div>
            <Head>
                <title>docomondo</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.png" />
            </Head>
            <main className={styles.container}>
                <div className={styles.carousel}>
                    <Text color={'white'}>{t('onboard.title')}</Text>
                    <Carousel
                        height={200}
                        slideGap="md"
                    >
                        <Carousel.Slide className={styles.slide}>
                            <Image src="/svg/docoScan.svg" alt="docomondo" width={250} height={250}/>
                            <Text color={'white'}>{t('onboard.import')}</Text>
                        </Carousel.Slide>
                        <Carousel.Slide className={styles.slide}>
                            <Image src="/svg/docoArchive.svg" alt="docomondo" width={250} height={250}/>
                            <Text color={'white'}>{t('onboard.file')}</Text>
                        </Carousel.Slide>
                        <Carousel.Slide className={styles.slide}>
                            {/* todo add find svg */}
                            <Image src="/svg/docoTada.svg" alt="docomondo" width={250} height={250}/>
                            <Text color={'white'}>{t('onboard.find')}</Text>
                        </Carousel.Slide>
                    </Carousel>
                </div>

                <div className={styles.main}>
                    <Group>
                        <Link
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore no idea what's going on....
                            href={router.pathname}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore no idea what's going on....
                            locale={'de'}
                        >
                            <div className={router.locale === 'de' ? styles.active : styles.inactive}>
                                {'de'}
                            </div>
                        </Link>
                        <Link
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore no idea what's going on....
                            href={router.pathname}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore no idea what's going on....
                            locale={'en'}
                        >
                            <div className={router.locale === 'en' ? styles.active : styles.inactive}>
                                {'en'}
                            </div>
                        </Link>
                    </Group>
                    {/* <h1 className={styles.title}>
                        {t('welcomeTo')}<a href="https://docomondo.com">docomondo!</a>
                    </h1> */}
                    <h1 className={styles.title}>
                        <Trans i18nKey="welcomeTo">
                        Welcome to <a href="https://docomondo.com">docomondo!</a>
                        </Trans>
                    </h1>
                    <Space h="md" />

                    <div className={styles.buttonGroupContainer}>
                        <Button className={styles.buttonWrapper} data-testid='login' onClick={() => setIsLoginModalOpened(true)}>{t('login')}</Button>
                        <Space h="md" />
                        <Button className={styles.buttonWrapper} variant='outline' onClick={() => setIsRegisterModalOpened(true)}>{t('signUp')}</Button>
                    </div>
                    <Space h="md" />
                    {/* {
                    baseUrl ? <p>baseURL: {baseUrl}</p> : 'no url 🥲 '
                }
                {
                    environment ? <p>environment: {environment}</p> : 'no environemnt 🥲'
                }
                {
                    <p>AMW: {AUTH_API_URL}</p>
                } */}
                </div>

            </main>
            <Modal
                opened={isLoginModalOpened}
                onClose={() => setIsLoginModalOpened(false)}
                title={<Text weight={700}>{'Login'}</Text>}
            >
                {
                    <Box sx={{ maxWidth: 340 }} mx="auto">
                        <form onSubmit={loginForm.onSubmit(async (values) =>{
                            setIsLoading(true);
                            const result = await authApiInterface.login({email: values.email, password: values.password, one_time_password: values.oneTimePassword});

                            if (result === false) {

                                setIsLoading(false);
                                if (!showOneTimePassword) {
                                    loginForm.setErrors({ password: t('signIn.error.password')});
                                } else {
                                    loginForm.setErrors({ oneTimePassword: t('signIn.error.2fa')});
                                }
                            } else if (typeof result === 'object' && result.api_token && result.email && result.name) {

                                signIn('credentials', {email: values.email, password: values.password, one_time_password: values.oneTimePassword, callbackUrl: `https://${process.env.NEXT_PUBLIC_BASE_URL}/dashboard`});
                            } else if (typeof result === 'object') {

                                if (result.oneTimePassword) {

                                    // render one time password field
                                    setIsLoading(false);

                                    return setShowOneTimePassword(true);

                                } else if (result.errors['one_time_password']) {
                                    loginForm.setErrors({ oneTimePassword: result.errors['one_time_password'][0]});
                                    setIsLoading(false);
                                }
                            }
                        })}>
                            <LoadingOverlay visible={isLoading} />
                            <TextInput
                                id={'email'}
                                required
                                label={t('index.email')}
                                placeholder="example@mail.com"
                                {...loginForm.getInputProps('email')}
                            />
                            <PasswordInput
                                id={'password'}
                                placeholder={t('index.password')}
                                label={t('index.password')}
                                {...loginForm.getInputProps('password')}
                                required
                            />
                            {showOneTimePassword && <TextInput
                                id={'oneTimePassword'}
                                placeholder={t('index.2fa')}
                                label={t('index.2fa')}
                                {...loginForm.getInputProps('oneTimePassword')}
                                required
                            />}
                            <Group position="right" mt="xl">
                                <Button data-testid="submit" loading={isLoading} type="submit">{t('submit')}</Button>
                            </Group>
                        </form>
                    </Box>
                }
            </Modal>
            <Modal
                opened={isRegisterModalOpened}
                onClose={() => setIsRegisterModalOpened(false)}
                title={<Text weight={700}>{t('signUp')}</Text>}
            >
                {
                    <Box sx={{ maxWidth: 340 }} mx="auto">
                        <form onSubmit={registerForm.onSubmit((values) =>{
                            setIsLoading(true);
                            authApiInterface.register(values.email, values.password, values.name).then(response => {
                                if (response === true) {
                                    // register was successful, signin
                                    signIn('credentials', {email: values.email, password: values.password, callbackUrl: `https://${process.env.NEXT_PUBLIC_BASE_URL}/dashboard`});
                                } else {
                                    setIsLoading(false);
                                    if (response.errors?.email) {
                                        registerForm.setErrors({email: response.message});
                                    } else if (response.errors?.password) {
                                        registerForm.setErrors({password: response.message});
                                    } else {
                                        toast.error('Something went wrong, try again later or contact us');
                                    }
                                }
                            });
                        })}>
                            <LoadingOverlay visible={isLoading} />
                            <TextInput
                                id={'name'}
                                required
                                label={t('index.name')}
                                placeholder={t('index.name')}
                                {...registerForm.getInputProps('name')}
                            />
                            <Space h="xs" />
                            <TextInput
                                id={'email'}
                                required
                                label={t('index.email')}
                                placeholder="example@mail.com"
                                {...registerForm.getInputProps('email')}
                            />
                            <Space h="xs" />
                            <PasswordInput
                                id={'password'}
                                placeholder={t('index.password')}
                                label={t('index.password')}
                                {...registerForm.getInputProps('password')}
                                required
                            />
                            <Space h="xs" />
                            <PasswordInput
                                id={'passwordConfirm'}
                                placeholder={t('index.passwordRepeat')}
                                label={t('index.passwordRepeat')}
                                {...registerForm.getInputProps('passwordConfirm')}
                                required
                            />
                            <Space h="xs" />
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Switch required {...registerForm.getInputProps('termsOfService', { type: 'checkbox' })}/>
                                <div style={{marginLeft: '1rem', marginTop: '0.5rem'}} >
                                    <Trans i18nKey="index.acceptTermsConditions">
                                        Welcome to <Text underline variant="link" component="a" target='_blank' href={router.locale === 'de' ? 'https://info.docomondo.com' : 'https://legal.docomondo.com'}>{'Terms & Conditions'}</Text>
                                    </Trans>
                                </div>
                            </div>
                            <Space h="xs" />
                            <div style={{display: 'flex', flexDirection: 'row'}}>

                                <Switch required {...registerForm.getInputProps('privacy', { type: 'checkbox' })}/>
                                <div style={{marginLeft: '1rem', marginTop: '0.5rem'}} >
                                    <Trans i18nKey="index.acceptPrivacyPolicy">
                                    I read the <Text underline variant="link" component="a" target='_blank' href={router.locale === 'de' ? 'https://info.docomondo.com/datenschutz-app' : 'https://legal.docomondo.com/app-privacy'}>{'Terms & Conditions'}</Text>
                                    </Trans>
                                </div>
                            </div>
                            <Group position="right" mt="xl">
                                <Button type="submit">{t('submit')}</Button>
                            </Group>
                        </form>
                    </Box>
                }
            </Modal>
            <footer className={styles.footer}>
                <p>
                    Powered by Docomondo
                </p>
                <div className={styles.logo}>
                    <Image src="/logo.png" alt="Logo of docomondo"
                        width={35}
                        height={35} />
                </div>
            </footer>
        </div>
    );
};

export const getStaticProps = async ({ locale } : {locale: string}) => ({
    props: {
        ...await serverSideTranslations(locale, ['common']),
    },
});

export default Home;